<template>
  <div id="admin-lead-kanban-view" class="lead-view">
    <v-row no-gutters>
      <v-col md="12">
        <contacts-list-to-assign />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import DefaultLayout from "@/components/layouts/DefaultLayout.vue";

export default {
  components: {
    ContactsListToAssign: () =>
      import("@/components/contacts/ContactsListToAssign.vue")
  },
  created() {
    this.$emit(`update:layout`, DefaultLayout);
  }
};
</script>
<style></style>
